<template>
  <v-menu
    bottom
    :close-on-content-click="close"
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-list
      :tile="false"
      flat
      nav
    >
      <v-list-item
        v-for="(p, i) in profile"
        :key="i"
        @click="git(p.action)"
      >
        <v-list-item-title v-text="p.title" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { giris } from './../../../db'
  export default {
    name: 'DefaultAccount',
    data: () => ({
      close: false,
      profile: [
        {
          title: 'Profile',
          action: 'profil',
        },
        {
          title: 'Log out',
          action: 'logout',
        },
      ],
    }),
    methods: {
      git (val) {
        if (val == 'logout') {
          giris.signOut().then(() => {
            this.$router.replace('/login')
          })
        }
        if (val == 'profil') {
          this.$router.replace('/profil')
          this.close = true
        }
      },
    },
  }
</script>
